@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300;1,400;1,700&family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
/* font-family: 'Lato', sans-serif;
font-family: 'Montserrat', sans-serif;*/
body {
  font-size: 15px;
  font-family: "Lato", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
P {
  font-size: 1.3em;
  line-height: 1.7em;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 400;
}

a {
  transition: all 0.5s;
}
a:focus,
a:hover,
.btn:focus,
.btn:hover {
  text-decoration: none;
  transition: all 0.5s;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #000;
  border-color: #000;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #fb9d1e05 !important;
}

/* Header */
.navbar-nav .nav-item {
  margin-left: 40px;
}

.navbar-nav .nav-item:last-child {
  margin-left: 20px;
}

.navbar-brand img {
  height: 3.5em;
}

.heading-style1 h2 {
  color: #48a068;
  margin-top: 0.5em;
  position: relative;
  padding-bottom: 0.8em;
  margin-bottom: 0.8em;
  font-size: 2.3em;
}
.heading-style1 h2:before {
  position: absolute;
  content: "";
  height: 6px;
  width: 40%;
  background-color: #ffc91f;
  border-radius: 3px;
  bottom: 0;
  left: 0;
}

.video-wrapper {
  border: solid 3px #48a068;
  overflow: hidden;
  display: block;
}

.video-wrapper iframe {
  float: left;
  margin: 0;
}

.welcome-block h2 {
  margin-left: 0.8em;
}
.welcome-block p {
  margin-left: 0.8em;
  font-size: 1.15em;
}

.heading-style1 h2 span {
  color: #fb9d1e;
  font-weight: 500;
  display: block;
}

.header .navbar-nav .nav-link {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #333;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}
.header .navbar-nav .active .nav-link {
  font-weight: 700;
  position: relative;
  color: #000;
}
.header .navbar-nav .active .nav-link:before {
  font-weight: 700;
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0;
  height: 2px;
  background: #48a068;
  border-radius: 5px;
  content: "";
}
.header .navbar-nav .btn-getTheApp,
.header .navbar-nav .btn-login {
  font-size: 0.8em;
  font-weight: 500;
  background: #fb9d1e;
  border: none;
  border-radius: 20px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
}
.header .navbar-nav .btn-getTheApp {
  padding-right: 75px;
  background: #48a068 url(../images/top-app-icon.png) no-repeat 90% 50%;
  background-size: auto 22px;
}

.header .navbar-nav .btn-login:hover {
  background: #000;
}
.header .navbar-nav .btn-getTheApp:hover {
  background-color: #2d7e4b;
}

.header .navbar-nav .nav-link.dropdown-toggle {
  padding-right: 18px;
}

.header .navbar {
  padding: 1rem 0;
}

.navbar-nav .nav-item .dropdown-toggle::after {
  border: 0 !important;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.9em;
  position: absolute;
  right: 8px;
  top: 10px;
}

.jumbotron {
  background: url(../images/banner-02.png) no-repeat 0 0;
  background-size: cover;
  color: #fff;
  padding: 6rem 2rem;
  border-radius: 0;
  margin-bottom: 0;
  position: relative;
}

.jumbotron.banner-buy {
  background-image: url(../images/banner.jpg);
}

.jumbotron.banner-sell {
  background-image: url(../images/bg-4.jpg);
}

.jumbotron h2 {
  color: #fff;
  font-size: 2.5em;
}
.jumbotron h4 {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 1.8em;
}

.btn-style1,
.jumbotron .btn {
  font-size: 1em;
  background: #fb9d1e;
  border: 0;
  padding: 8px 25px;
  border-radius: 20px;
  line-height: 1.2em;
  color: #fff;
}

#home .btn-primary {
  text-transform: uppercase;
  font-size: 0.85em;
  line-height: 1.15em;
}

.sell-whoShouldBuy {
  margin-top: 0;
}

.btn-sm.btn-style1 {
  font-size: 0.75em;
  padding: 8px 20px;
  margin-top: 1em;
}
.jumbotron .search-area .input-field {
  background: #fff;
  padding: 8px 30px;
  border-radius: 20px;
  border: 0;
  width: 28em;
  padding-right: 3.5em;
  height: 45px;
  font-weight: 400;
  font-style: italic;
}

.search-area {
  position: relative;
}

.jumbotron .search-area .btn-md {
  margin-left: -3em;
  position: absolute;
  height: 45px;
  line-height: 45px;
  padding: 0 25px;
}
h1.title-top {
  position: absolute;
  top: 0;
  left: 50%;
  background: #fb9d1e;
  color: #fff;
  text-align: center;
  width: 30%;
  margin-left: -15%;
  padding: 10px 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 2.8rem;
  padding: 0.2em 0;
}

.jumbotron.banner-sell .search-area,
.jumbotron.banner-buy .search-area {
  margin: 0;
}

h2.buy-sell-title {
  font-size: 1.55em;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.ul-none {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
input {
  overflow: visible;
  outline: none;
}

.btn-style1.btn-green:active,
.btn-style1.btn-green:focus,
.btn-style1.btn-green:hover,
.btn-style1:active,
.btn-style1:focus,
.btn-style1:hover,
.jumbotron .btn:active,
.jumbotron .btn:focus,
.jumbotron .btn:hover {
  background: #000;
  color: #fff;
}

.btn-style1.btn-green {
  background: #48a068;
}
.btn-style1.btn-green {
  background: #48a068;
}

.btn.btn-proceedCheckout {
  border-radius: 4px;
}

.getStarted-sec {
  padding-top: 60px;
  padding-bottom: 20px;
}

.getStarted-sec h2 {
  margin-bottom: 2.1em;
  font-weight: 600;
  font-size: 1.4em;
}
.getStarted-sec .getStarted-icon {
  float: left;
  width: 4.5em;
  margin-right: 0.2em;
}

.getStarted-sec ul li {
  width: 20%;
}

.getStarted-sec .getStarted-text {
  float: right;
  width: 65%;
  margin-right: 5%;
}
.getStarted-sec .getStarted-text h3 {
  font-size: 0.85em;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.getStarted-sec .getStarted-text p {
  font-size: 0.65em;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  line-height: 1.3em;
}

.heading-style1.buy_page h2 span {
  display: inline;
  font-weight: 600;
}

.getStarted-sec .getStarted-text a {
  font-size: 0.75em;
  color: #000;
  font-weight: 700;
  margin-top: 0.3em;
  display: block;
}
.getStarted-sec .getStarted-text a .fas {
  font-size: 0.82em;
  margin-left: 10px;
  transition: all 0.5s;
}

.getStarted-sec li:hover .getStarted-text a:hover {
  color: #fb9d1e;
}

.getStarted-sec li:hover .getStarted-text a .fas {
  margin-left: 20px;
  transition: all 0.5s;
}

#home .welcome_section .container:before {
  position: absolute;
  left: -2em;
  bottom: 1em;
  content: "";
  background: url(../images/welcome-bg-img.png) no-repeat 0 0;
  width: 9em;
  height: 9em;
}

.welcome_section .container {
  background: url(../images/welcome_section-bg.png) no-repeat 50% 30px;
  padding-top: 6em;
  padding-bottom: 6.5em;
  position: relative;
}

#home .jumbotron h4 {
  margin-bottom: 0.8em;
}

#home .welcome_section .container {
  background-position: 50% -50px;
}

#home .welcome-block h2 {
  font-size: 2.2em;
  margin-left: 0.4em;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
  margin-top: 0.2em;
}

#home .welcome-block {
  margin-top: 0.6em;
}

#home .buy_sell_section ul {
  margin-bottom: 0.5em;
}

.buy_sell_section {
  padding-top: 3em;
  background: url(../images/buy_sell_section_bg.png) no-repeat 0 0;
  background-size: 100% auto;
}

.buy_sell_section .img-block img {
  width: 12em;
}

.sb-btn-group {
  text-align: center;
  display: block;
  clear: left;
}

.buy_sell_section h3 {
  font-size: 2em;
  color: #fff;
  font-weight: 400;
  margin-top: 0.6em;
}

.buy_sell_section h3 span {
  color: #fb9d1e;
}

.buy_sell_section .buyleft-section h3 span {
  color: #ffc91f;
}

.buy_sell_section h2 {
  font-size: 2em;
  color: #fff;
  text-transform: uppercase;
}

.buy_sell_section ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  margin-top: 2.4em;
  display: block;
  margin-bottom: 1.5em;
  height: 8em;
}

.buy_sell_section ul li {
  float: left;
  margin-right: 2.8em;
  transition: all 0.5s;
}
.buy_sell_section ul li:hover {
  transition: all 0.5s;
  margin-top: -0.4em !important;
}
.buy_sell_section .buyleft-section ul li:nth-child(5) {
  margin-top: 0;
}

.buy_sell_section .buyleft-section ul li:nth-child(4),
.buy_sell_section ul li:nth-child(2) {
  margin-top: 0.2em;
}
.buy_sell_section .buyleft-section ul li:nth-child(3),
.buy_sell_section ul li:nth-child(3) {
  margin-top: 0.4em;
}
.buy_sell_section .buyleft-section ul li:nth-child(2),
.buy_sell_section ul li:nth-child(4) {
  margin-top: 0.6em;
}
.buy_sell_section .buyleft-section ul li:nth-child(1),
.buy_sell_section ul li:nth-child(5) {
  margin-top: 0.8em;
}

.buy_sell_section .buyleft-section ul {
  float: right;
  margin-right: 2.5em;
}

.buy_sell_section .sellright-section ul {
  float: left;
  margin-left: 2.5em;
}

.buy_sell_section ul li img {
  max-width: 4.5em;
}
.buy_sell_section ul li h3 {
  font-size: 0.8em;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.buy_sell_section ul li:last-child {
  margin-right: 0;
}

.buy_sell_section .btn.btn-buying {
  background: #ffc91f;
}

.wsb-list-img img {
  border-radius: 10px;
}

/*gettheapp_section */

.gettheapp_section {
  padding-top: 6em;
  padding-bottom: 6em;
  position: relative;
  margin-bottom: 4em;
}

#home .gettheapp_section .container {
  position: relative;
}
#home .gettheapp_section .container:after {
  position: absolute;
  content: "";
  top: -10em;
  left: 40%;
  background: url("../images/gettheapp_bg.png") no-repeat 0 0;
  width: 15em;
  height: 15em;
  background-size: 15em auto;
}

.gettheapp_section:before {
  position: absolute;
  content: "";
  top: 0;
  left: -5%;
  background: url("../images/get-the-app-bg.png") no-repeat 0 50%;
  right: 0;
  bottom: 0;
}

#home .gettheapp_section:after {
  position: absolute;
  content: "";
  top: 0;
  right: 120px;
  background: url(../images/bg-2.png) no-repeat 100% 50%;
  background-size: auto 100%;
  bottom: 0;
  width: 50%;
}

#buying-xchange-values:before {
  background: url(../images/cart-img.png) no-repeat 100% 0;
}

.gettheapp_section.sell-page:before {
  background: url(../images/sell-img-3.png) no-repeat 100% 0 !important;
}

.gettheapp_section.sell-page {
  padding-top: 2em;
}

.gettheapp_section h2 {
  position: relative;
  float: left;
  padding-right: 1.4em;
  font-size: 2.3em;
}
.gettheapp_section h2 .icon-mob {
  width: 0.8em;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}

.gettheapp_section h3 {
  font-size: 1.6em;
  color: #48a068;
  clear: left;
  display: block;
}

.gettheapp_section h4 {
  font-size: 0.95em;
  color: #000;
  margin-top: 0.8em;
}

#buying-xchange-values {
  padding-bottom: 4em;
  position: relative;
  background: none;
  margin-bottom: 0;
}

#buying-xchange-values:after {
  content: "";
  position: absolute;
  top: -27%;
  left: -5%;
  width: 50%;
  height: 100%;
  z-index: -1;
  background: url(../images/buying-xchange-values.png) no-repeat 0 0;
  background-size: 20em auto;
}

#buying-xchange-values h2 {
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.9em;
}
#buying-xchange-values h2 span {
  color: #479f68;
}

#buying-xchange-values h4 {
  color: #000;
  font-size: 0.75em;
  line-height: 1.5em;
  font-weight: 500;
  margin-bottom: 3em;
  clear: left;
  display: block;
  max-width: 70%;
}

#buying-xchange-values .xchange-value ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}
#buying-xchange-values .xchange-value ul li {
  float: left;
  width: 50%;
  margin-bottom: 1.3em;
  font-size: 0.8em;
  line-height: 1.5em;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding-left: 30px;
}
#buying-xchange-values .xchange-value ul li a {
  color: #000000;
}

#buying-xchange-values .xchange-value ul li:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  margin-top: -5px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: #ffc91f;
}

.app-widget {
  margin-top: 2.5em;
}
.app-widget h4 {
  color: #ff9200;
  font-size: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
}
.app-widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-widget ul li {
  float: left;
  margin: 0 1em 0 0;
  padding: 0;
}
.app-widget ul li img {
  height: 3em;
}

/* recent reviews*/
.recent_reviews_section {
  margin-bottom: 5em;
}
.recent_reviews_section h2 {
  margin-bottom: 1.5em;
  font-weight: 500;
  font-size: 1.3em;
  text-transform: uppercase;
}
.recent_reviews_section h2 span {
  font-weight: bold;
}
.recent_reviews_section .card {
  border: none;
  background: #f9f9f9;
  border-radius: 10px;
}
.recent_reviews_section .card-body .custmoer_name,
.recent_reviews_section .card-body .card-text {
  font-size: 0.8em;
  color: #000;
  font-weight: 500;
  line-height: 1.7em;
  font-family: "Montserrat", sans-serif;
}
.recent_reviews_section .card-title {
  font-size: 1.1em;
  font-weight: 600;
  clear: both;
  margin-bottom: 0.3rem;
}

.review-rating {
  list-style: none;
  margin: 0 0 1.2em;
  padding: 0;
  float: left;
  width: 100%;
  display: block;
}

.review-rating li {
  float: left;
  margin-right: 0.2em;
  color: #ffa200;
}

.controls-top .btn-floating {
  top: 50%;
  position: absolute;
  left: -20px;
  z-index: 5;
  background: #fff;
  box-shadow: 1px 1px 4px #aaa;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #333;
  font-size: 20px;
  margin-top: -20px;
}
.controls-top .btn-floating.btn-right {
  right: -20px;
  left: auto;
}
.controls-top .btn-floating:hover {
  left: -25px;
}
.controls-top .btn-floating.btn-right:hover {
  right: -25px;
  left: auto;
}

.whoShouldBuy .whoShouldBuyWrap {
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 10%);
  padding: 4em 2em;
  border-radius: 20px;
  margin-bottom: 0;
  z-index: 1;
  position: relative;
  background-color: #fff;
}

.wsb-list h3 {
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 0.9em;
  margin-bottom: 0;
}

.wsb-list a {
  font-size: 0.75em;
  font-weight: 500;
  color: #479f68;
  text-decoration: underline;
}
.wsb-list a:hover {
  color: #000;
}

.howToBuy h2,
.whoShouldBuyWrap h2 {
  text-transform: uppercase;
  color: #48a068;
  font-weight: 600;
  font-size: 1.7em;
  margin-top: 2em;
  margin-bottom: 0;
}
.howToBuy h2 span,
.whoShouldBuyWrap h2 span {
  display: block;
  color: #000;
  font-size: 0.7em;
}
.bg-whoShouldBuy {
  background: url(../images/icon-09.png) no-repeat 80% 50%;
  background-size: auto 100%;
}

.whybuy {
  padding-top: 8em;
  padding-bottom: 5em;
  background: url("../images/banner_rm1.png") no-repeat 50% 0;
  background-size: cover;
  margin: -55px 0 2em;
}

.whybuy h2 {
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 1.8em;
  margin-top: 2em;
}
.whybuy h2 span {
  display: block;
  color: #fff;
  font-size: 0.7em;
}

.wb-list-img {
  text-align: center;
  margin-top: 2.3em;
}

.wb-list-img img {
  height: 6.2em;
}

.wb-list h3 {
  font-size: 1.2em;
  font-weight: 500;
  margin-top: 0.9em;
  margin-bottom: 0.2em;
  color: #fff;
}

.wb-list a {
  font-size: 0.75em;
  font-weight: 500;
  color: #fff;
  text-decoration: underline;
}
.wb-list a:hover {
  color: #fb9d1e;
}

.howtobuyright {
  position: relative;
}

.img-progress {
  position: absolute;
  bottom: 100px;
  left: 0;
}

.welcome-block {
  margin-top: 1.2em;
  margin-left: 2em;
}

.howToBuy {
  padding-top: 80px;
  position: relative;
  padding-bottom: 100px;
}

.howToBuy:before {
  /* background: url("../images/how-to-sell-bg.png") no-repeat 50% 0; */
  /* content: ""; */
  position: absolute;
  top: 72%;
  left: -7%;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.htb-prog-img {
  text-align: center;
  margin-bottom: 100px;
}

.htb-prog-img img {
  height: 6.05em;
}

.htb-prog-cont {
  text-align: center;
  margin-top: 1.5em;
}

.htb-prog-cont h4 {
  font-size: 0.9em;
  font-weight: 800;
}

.htb-prog-cont h5 {
  font-size: 1.1em;
  font-weight: 500;
}

.htp-step1 h4 {
  color: #48a068;
}
.htp-step2 h4 {
  color: #4fbfb9;
}
.htp-step3 h4 {
  color: #ffd239;
}
.htp-step4 h4 {
  color: #ff9d00;
}

.img-progress img {
  max-width: 91%;
  margin-left: 10px;
}

.gettheapp_section.style2 {
  background: url(../images/gettheApp-bg.jpg) no-repeat 0 50%;
  background-size: 100% auto;
  margin-top: 4em;
  margin-bottom: 6em;
  padding-top: 5em;
  padding-bottom: 5em;
  z-index: 999;
  position: relative;
}
.gettheapp_section.style2:before {
  background: url(../images/bg-3.png) no-repeat 100% 50%;
  top: -12%;
  bottom: -12%;
  right: 0.5%;
}

/* shop page */
.shop-feature-product {
  background: #f7f7f6;
}

.shop-filter-nav {
  border-top: solid 2px #dddddd;
  border-bottom: solid 2px #dddddd;
}

.shop-filter-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.shop-filter-nav ul li {
  width: 20%;
  text-align: center;
  position: relative;
}

.shop-filter-nav ul li:before {
  content: "";
  border-left: solid 1px #dddddd;
  border-right: solid 1px #dddddd;
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.shop-filter-nav ul li a {
  display: block;
  color: #000;
  padding: 8px 15px;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 1em;
}

.shop-filter-nav ul li.active a,
.shop-filter-nav ul li a:hover {
  background: #48a068;
  color: #fff;
}

.search-block-sec {
  background: #48a068;
  text-align: center;
  padding: 2.5em 0;
  margin-bottom: 3em;
}

.search-block-sec .search-area {
  width: 77%;
}

.search-block-sec .input-field {
  background: #fff;
  padding: 10px 30px;
  border-radius: 28px;
  border: 0;
  width: 100%;
  padding-right: 8.9em;
  height: 56px;
  padding-left: 50px;
}

.search-area {
  width: 70%;
  margin: auto;
  position: relative;
}
.search-block-sec .fa-search {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #ccc;
}

.search-block-sec .btn-md {
  right: 0;
  position: absolute;
  height: 56px;
  border-radius: 28px;
  line-height: 36px;
}

.shop-feature-product h2 {
  margin-top: 1.2em;
  padding-bottom: 15px;
  font-size: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 25px;
}

.shop-feature-product h2:before {
  background-color: #ffc91f;
  height: 4px;
  left: 50%;
  width: 260px;
  margin-left: -130px;
  bottom: 0;
  border-radius: 5px;
  content: " ";
  position: absolute;
}

.pro-block {
  border: solid 1px #48a068;
  box-shadow: 1px 1px 4px #aaa;
  background: #fff;
  text-align: center;
  margin-bottom: 1.75em;
}

.pro-block .pro-block-img img {
  max-width: 100%;
}

.pro-block-content {
  padding-bottom: 1em;
  text-align: left;
  padding-left: 15px;
}

.pro-block-content h3 {
  font-size: 0.98em;
  margin-top: 0.5em;
  font-weight: 500;
  margin-bottom: 1em;
}

.pro-block .pro-block-img {
  border-bottom: solid 1px #48a068;
  margin-bottom: 15px;
  display: block;
  height: 15em;
  position: relative;
}

.pro-block .pro-block-img .icon-wish-list {
  position: absolute;
  right: 15px;
  bottom: 15px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background: #fff;
  border: solid 1px #ccc;
  border-radius: 50%;
  text-align: center;
  transition: all 0.5s;
}

.pro-block .pro-block-img .icon-wish-list:hover {
  border-color: #333;
  transition: all 0.5s;
}

.pro-block .pro-block-img .icon-wish-list i {
  color: #ccc;
  transition: all 0.5s;
}
.pro-block .pro-block-img .icon-wish-list.active i,
.pro-block .pro-block-img .icon-wish-list:hover i {
  color: #ff9200;
  transition: all 0.5s;
  font-size: 18px;
}

.pro-block .pro-block-img .icon-add-cart {
  position: absolute;
  right: 15px;
  bottom: 55px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background: #fff;
  border: solid 1px #ccc;
  border-radius: 50%;
  text-align: center;
  transition: all 0.5s;
}

.pro-block .pro-block-img .icon-add-cart:hover {
  border-color: #333;
  transition: all 0.5s;
}

.pro-block .pro-block-img .icon-add-cart i {
  color: #000;
  transition: all 0.5s;
}
.pro-block .pro-block-img .icon-add-cart.active i,
.pro-block .pro-block-img .icon-add-cart:hover i {
  color: #ff9200;
  transition: all 0.5s;
  font-size: 18px;
}

.pro-block .pro-price {
  color: #48a068;
  font-size: 0.95em;
  font-weight: bold;
  margin-bottom: 0;
}

.shop-content .pro-block {
  border: solid 1px #c8c8c8;
  box-shadow: none;
}

.shop-content .pro-block .pro-block-img {
  border-color: #c8c8c8;
}

.pagination-wrapper {
  border-top: solid 2px #c8c8c8;
  padding-top: 1.2em;
  text-align: right;
  margin-top: 1em;
}

.pagination-wrapper .pagination {
  float: right;
}
.page-filter {
  float: right;
}

.page-filter label {
  color: #000;
  margin-right: 5px;
}

.page-filter select {
  border: solid 1px #c8c8c8;
  padding: 7px 10px;
  border-radius: 0.25rem;
}

.pagination-wrapper .pagination .page-link {
  border-color: #c8c8c8;
  margin-left: 5px;
  border-radius: 0.25rem;
  color: #000;
}

.page-item.activepage .page-link {
  background: #eff0f1;
}

.page-item.disabled .page-link {
  opacity: 0.5;
  cursor: crosshair;
}

.full-block {
  width: 100%;
  float: left;
}

.shopTop-filter {
  border-bottom: solid 1px #c8c8c8;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.filter-view ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 11px;
}

.filter-view ul li {
  margin-right: 15px;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 4px 15px 6px;
  white-space: nowrap;
}

.filter-view ul li label {
  margin-right: 5px;
  margin-bottom: 0;
}

.filter-view ul li a .fas {
  color: #666;
}

.filter-view ul li a:hover .fas {
  color: #000;
}

.filter-widget {
  margin-bottom: 1.2em;
}

.filter-widget .fw-head {
  border-bottom: solid 1px #c8c8c8;
  display: block;
  float: left;
  width: 100%;
}

.filter-widget .fw-head .btn {
  text-align: left;
  padding: 15px 0;
  color: #333;
  font-weight: 500;
  font-size: 1em;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  outline: none;
  box-shadow: none;
  position: relative;
}

.filter-widget .fw-head .btn .fas {
  position: absolute;
  right: 0;
  top: 20px;
}

.filter-widget .card-body {
  border: none;
  padding: 15px 0 0;
  clear: both;
  display: block;
}

.filter-widget ul {
  list-style: none;
  padding: 0;
  margin: 0.5em 0 0;
  float: left;
}

.filter-widget ul li {
  padding: 0.3em 0;
  margin: 0;
  float: left;
  width: 100%;
  color: #333;
}

.filter-widget ul li a {
  color: #333;
}

.filter-widget ul li a:hover {
  color: #fb9d1e;
}

.filter-widget ul li input {
  margin-right: 5px;
}

.title-shopBy {
  border-bottom: solid 1px #c8c8c8;
  padding-bottom: 0.6em;
  font-size: 1.1em;
  margin-top: 1em !important;
}

.priceMinMax input[type="submit"] {
  border: 0;
  background: none;
  cursor: pointer;
  color: #2d7e4b;
  padding: 0;
  padding-top: 5px;
  font-size: 16px;
}

.priceMinMax .col-4 {
  padding: 0 0 0 15px;
}

.priceMinMax .col-4:first-child {
  position: relative;
}
.priceMinMax .col-4:first-child:after {
  position: absolute;
  content: "-";
  right: -12px;
  top: 0;
  color: #000;
  font-size: 20px;
}

/* dashboard */

.col-2-layout .box-content .heading-sec {
  margin-bottom: 1.4em;
}

.col-2-layout {
  width: 100%;
  float: left;
  padding: 1.2em 1.5%;
  background-color: #f6f6f6;
  display: table;
}
.col-2-layout .left-sidebar {
  height: 100%;
  padding-right: 2%;
  width: 17%;
  float: left;
}

.col-2-layout .right-content {
  width: 83%;
  float: left;
  height: 100%;
  box-sizing: border-box;
}

.emptyspace50 {
  height: 50px;
  display: block;
  float: left;
  clear: left;
}
.emptyspace100 {
  height: 100px;
  display: block;
  float: left;
  clear: left;
}
.emptyspace150 {
  height: 150px;
  display: block;
  float: left;
  clear: left;
}

.col-2-layout .box-content {
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 10%);
  padding: 1.5em;
  border-radius: 15px;
  background: #fff;
  overflow: hidden;
  position: relative;
}

.col-2-layout .box-content.minColheight {
  min-height: 35em;
}

.col-2-layout .left-sidebar .box-content {
  height: 100%;
}

.col-2-layout .box-content.title-box {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 1.5em;
  box-shadow: 0px 0 10px 0px rgb(0 0 0 / 05%);
}

.sidebar {
  border: none;
  margin-top: 1.5em;
}

.sidebar li {
  margin-bottom: 0.8em;
}

.sidebar li a {
  color: #333;
  font-size: 0.9em;
  font-weight: 500;
}
.sidebar li.has-submenu > a.nav-active,
.sidebar li.nav-active a {
  background-color: #48a068;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0px 0 5px 0px rgb(0 0 0 / 10%);
}

.sidebar li.has-submenu > a.nav-active:hover,
.sidebar li.nav-active a:hover {
  background-color: #296840;
  color: #fff !important;
}

.sidebar li.nav-active a {
  color: #fff;
}

.sidebar li .submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sidebar li.has-submenu {
  position: relative;
}

.sidebar li.has-submenu > .nav-link {
  background-color: #f6f6f6;
  border-radius: 10px;
}

.sidebar li.has-submenu > .nav-link:hover {
  background-color: #eaeaea;
}

.sidebar li.has-submenu > .submenu {
  margin-top: 1em;
  padding-left: 0.5em;
  padding-right: 0;
}

.sidebar li.has-submenu > .submenu li {
  margin-bottom: 0.4em;
}

.sidebar li a:hover {
  color: #fb9d1e;
}
.sidebar li.has-submenu i {
  position: absolute;
  right: 10px;
  top: 12px;
}

.logout-btn {
  margin-top: 0;
  border-top: solid 2px #f6f6f6;
  padding-top: 0;
  position: absolute;
  bottom: 25px;
  width: 100%;
}
.logout-btn a {
  color: #333;
}

.logout-btn a:hover {
  color: #fb9d1e;
}

.logout-btn img {
  margin: 0 10px;
}

#dashboard .form-control {
  border-radius: 25px;
  padding: 0.6rem 0.85rem;
  border-color: #b6b9b3;
  color: #000;
  font-size: 0.95em;
  font-weight: 500;
  margin-bottom: 0.2em;
}

#dashboard .right-content a {
  text-decoration: underline;
  color: #000;
}

.form-control:focus {
  border-color: #9a9a9a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 25%);
}

.form-sec h3 {
  font-size: 0.8em;
  font-weight: 700;
  color: #000;
  padding-left: 15px;
  margin-bottom: 0.8em;
  display: block;
}

.form-sec .row .col-md-4:first-child {
  padding-right: 5px;
}

.heading-sec {
  position: relative;
  margin-bottom: 2em;
  border-bottom: solid 1px #000000;
}

.heading-sec:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  width: 180px;
  height: 3px;
  background: #48a068;
  border-radius: 2px;
}

.heading-sec h2 {
  text-transform: uppercase;
  font-size: 1.1em;
  color: #000;
  padding-left: 1.2em;
}

.box-content.title-box {
  position: relative;
}

.box-content.title-box .page-title {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  line-height: 1.7em;
  padding-left: 1.5em;
  padding-right: 2em;
  font-size: 1.8em;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: #fb9d1e;
  border-bottom-right-radius: 30px;
}

.right-top-link {
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
}

.right-top-link h3 {
  font-size: 0.8em;
  margin: 0;
  padding: 0;
  font-weight: 500;
  line-height: 3.5em;
  padding-left: 2em;
  background: url(../images/icon-user.png) no-repeat 0 50%;
  background-size: 1em auto;
  float: left;
}

.right-top-link .link-alert {
  float: left;
  margin-top: 0.7em;
  margin-right: 1.5em;
}
.right-top-link .link-alert i {
  color: #979797;
  font-size: 1.2em;
}

.right-content .btn {
  padding: 10px 40px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.right-top-link .link-alert:hover i {
  color: #000;
}

@media (max-width: 767px) {
  .search-blk {
    width: 150px !important;
  }
  .col-2-layout .left-sidebar {
    width: 100%;
    margin-bottom: 2em;
  }

  .col-2-layout .right-content {
    width: 100%;
  }
  #dashboard .form-control {
    margin-bottom: 1em;
  }

  .box-content.title-box {
    height: 120px;
  }
  .box-content.title-box .page-title {
    max-height: 56px;
  }
  .right-top-link {
    right: auto;
    left: 15px;
    top: 60px;
    bottom: auto;
  }
} /* end media */

/*Footer*/
.footer {
  background: #4ba26b url(../images/bg-3.jpg) no-repeat 0 0;
  background-size: cover;
  color: #fff;
  margin-top: 2.5em;
  clear: left;
}
.footer * {
  color: #fff;
}
.footer h5 {
  font-size: 0.85em;
  white-space: nowrap;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 8px;
  margin-bottom: 1em;
}

.footer h5:before {
  background-color: #ffa200;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 160px;
  height: 3px;
  content: "";
  border-radius: 2px;
}

.footer ul li a {
  font-size: 0.75em;
  padding: 5px 0;
  font-weight: 400;
  display: block;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}
.footer ul li a:hover {
  color: #ffa200;
  padding-left: 4px;
}

#footer-last-col h5 {
  clear: left;
  float: left;
  text-align: right;
  margin-bottom: 0;
  width: 100%;
}
#footer-last-col h5:before {
  display: none;
}

#footer-last-col * {
  text-align: right;
}
#footer-last-col .btn-style1 {
  font-size: 1em;
  padding: 0.5em 1.5em;
}

#footer-last-col ul {
  list-style: none;
  margin: 0 0 1.5em;
  padding: 0;
  float: right;
  display: block;
}

#footer-last-col ul li {
  float: left;
  margin-right: 0.5em;
}
#footer-last-col ul li:last-child {
  margin-right: 0;
}

#footer-last-col ul li img {
  width: 1.8em;
}

#footer-last-col .app-icon {
  width: 98%;
}

#footer-last-col .btn-sm {
  padding: 0.5em 1em;
  line-height: 1em;
  margin-top: 1.32em;
  text-transform: uppercase;
}

.copyright {
  background: #292929;
  color: #fff;
  padding: 20px 0;
  font-size: 0.78em;
  text-align: center;
}

.filter-widget .fw-head .btn .fas:before {
  content: "";
  position: absolute;
  right: 2px;
  background: url(../images/nav-arrow-icon.png) no-repeat 0 0;
  width: 25px;
  height: 25px;
}
.mob-view {
  display: none !important;
}

.header .navbar-nav .nav-item .dropdown-toggle::after {
  content: " " !important;
  background: url(../images/down-arrow.png) no-repeat 0 0;
  height: 30px;
  width: 30px;
  right: -30px;
  top: 16px;
}

@media (min-width: 768px) and (max-width: 992px) {
  #buying-xchange-values:before,
  .gettheapp_section.sell-page:before {
    background-size: 42% !important;
    background-position: 100% 100% !important;
  }
  .howToBuy:before,
  .gettheapp_section.style2:before {
    background-size: 48% !important;
  }
  .app-widget ul li {
    width: 110px;
  }
  .app-widget ul li img {
    max-width: 100%;
    height: auto;
  }
  .gettheapp_section.style2 {
    margin-top: 0;
    padding-top: 0;
    background-size: 120% auto;
    padding-bottom: 1em;
  }
  .app-widget {
    margin-top: 0.7em;
  }
  h1.title-top {
    font-size: 1.8rem;
  }
  .getStarted-sec ul li {
    text-align: center;
  }
  .getStarted-sec .getStarted-icon {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .getStarted-sec .getStarted-text {
    width: 100%;
    margin-right: 0;
  }
  .img-progress img {
    max-width: 95%;
  }
  .htb-prog-img {
    margin-bottom: 65px;
  }
  .howToBuy h2,
  .whoShouldBuyWrap h2 {
    margin-top: 0;
  }
  .welcome_section .container {
    padding-bottom: 0;
  }
  .bg-whoShouldBuy h2 {
    font-size: 1.3em;
  }
  #footer-last-col ul li img {
    width: 1.4em;
  }

  .pro-block .pro-block-img {
    height: 11em;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .getStarted-sec .getStarted-text {
    width: 54%;
  }
  #buying-xchange-values:before,
  .gettheapp_section.sell-page:before {
    background-size: 40% !important;
    background-position: 100% 100% !important;
  }
  .gettheapp_section.style2:before {
    background-size: 45% !important;
  }
}

@media (min-width: 1201px) and (max-width: 1679px) {
  .getStarted-sec .getStarted-text {
    width: 60%;
  }
  #buying-xchange-values:before,
  .gettheapp_section.sell-page:before {
    background-size: 50% !important;
  }
  .gettheapp_section.style2:before {
    background-size: 50% !important;
  }
  .gettheapp_section .container:after {
    left: 38%;
    background-size: 12em auto;
  }
}

@media (min-width: 992px) {
  #navbarNav {
    display: block !important;
  }
  #footer-last-col .col-6 {
    padding: 0;
  }

  .shop-content .row .col-md-8 {
    padding-left: 2em;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .navbar-nav .nav-item:last-child,
  .navbar-nav .nav-item {
    margin-left: 0;
  }

  .navbar-nav {
    font-size: 18px;
  }
  .header .navbar-nav .active .nav-link:before {
    left: 0;
  }

  .header .navbar-nav .nav-item .dropdown-toggle::after {
    right: 0;
  }

  .equalColumn {
    height: auto !important;
  }
  .col-2-layout .left-sidebar .box-content {
    padding-bottom: 100px;
  }
  .search-block-sec .search-area {
    width: 100%;
  }
  .search-block-sec .fa-search {
    top: 22px;
  }

  .shop-content .col-md-8 .row .col-md-4 {
    padding-right: 10px;
    padding-left: 10px;
  }
  #buying-xchange-values,
  .gettheapp_section.sell-page {
    text-align: left;
  }
  #home .welcome-block h2,
  .welcome-block {
    margin-left: 0;
  }
  .mob-view {
    display: block !important;
  }
  .gettheapp_section:before,
  .gettheapp_section.style2:before {
    display: none !important;
  }
  .gettheapp_section.style2 {
    background-size: cover;
  }
  .gettheapp_section h2 {
    float: none;
  }
  h1.title-top {
    width: 80%;
    margin-left: -40%;
    font-size: 1.5rem;
  }
  .jumbotron h2 {
    font-size: 2.5em;
  }
  .jumbotron .search-area .input-field {
    width: 100%;
  }

  .jumbotron .search-area .input-field {
    padding-right: 30px;
  }

  .jumbotron .search-area .btn-md {
    right: 75px;
    padding: 8px 20px;
    bottom: -60px;
  }

  .getStarted-sec ul.d-flex {
    display: block !important;
  }

  .getStarted-sec ul li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 25px;
  }
  .getStarted-sec .getStarted-text,
  .getStarted-sec .getStarted-icon {
    float: none;
    width: 100%;
  }

  .getStarted-icon img {
    height: 96px;
  }

  .welcome_section {
    width: 100%;
    clear: left;
  }
  .welcome-block p {
    margin-left: 0;
  }
  .welcome-block h2 {
    margin-left: 0;
  }

  .welcome_section .container {
    padding-bottom: 30px;
  }
  .btn-sm.btn-style1 {
    margin-bottom: 30px;
  }

  .wsb-list {
    margin-bottom: 30px;
  }

  .whybuy h2 {
    text-align: center;
  }
  .howToBuy,
  .whoShouldBuy,
  .gettheapp_section,
  .app-widget ul,
  .whybuy {
    text-align: center;
  }

  .htb-prog-img {
    margin-bottom: 0;
  }

  .htb-prog-cont {
    margin-bottom: 40px;
  }
  #home .gettheapp_section:after,
  .img-progress {
    display: none;
  }

  .gettheapp_section h2 .icon-mob {
    position: relative;
  }

  .app-widget ul li {
    float: none;
    display: inline;
    margin: 0;
  }
  .footer h5 {
    white-space: normal;
  }

  #footer-last-col {
    margin-bottom: 30px;
  }
  .getStarted-sec .getStarted-text {
    height: 90px;
  }

  .buy_sell_section {
    background: none;
  }

  .buyleft-section {
    background: url(../images/mob-buy-img.png) no-repeat 50% 0;
    background-size: 100% auto;
    padding-top: 15px;
  }

  .sellright-section {
    background: url(../images/mob-sell-img.png) no-repeat 50% 0;
    background-size: 100% auto;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 15px;
  }

  .sb-btn-group {
    float: left;
    width: 100%;
  }
  .buy_sell_section ul {
    height: auto;
  }

  .buy_sell_section .buyleft-section ul {
    float: left;
  }

  .buy_sell_section ul li {
    height: 110px;
    margin-top: 0 !important;
  }

  .buy_sell_section h2 {
    font-size: 20px;
  }
  .buy_sell_section h3 {
    font-size: 22px;
  }

  .buy_sell_section .img-block img {
    width: 100px;
  }
  .gettheapp_section h2 {
    margin-top: 1em;
  }

  .navbar-nav.float-right {
    width: 100%;
  }
  .navbar-nav .nav-item {
    margin-bottom: 15px;
  }

  .navbar-light .navbar-toggler {
    background: #fb9d1e;
  }
  .navbar-toggler-icon {
    color: #fff;
  }

  .search-area {
    width: 100%;
  }

  .search-block-sec .btn-md {
    right: 0;
  }

  .search-block-sec .input-field {
    padding-right: 155px;
  }

  .shopTop-filter {
    margin-top: 25px;
  }

  .page-filter {
    float: left;
    width: 100%;
  }
  .shop-filter-nav {
    overflow: hidden;
  }
  .shop-filter-nav ul {
    display: block !important;
  }
  .shop-filter-nav ul li {
    width: 50%;
    float: left;
  }
} /* max max-width: 767px*/

/* end media */

/* media query for large desktop*/

@media screen and (min-width: 1201px) and (max-width: 1359px) {
  #footer-last-col ul li img {
    width: 1.2em;
  }

  #footer-last-col {
    font-size: 13px;
  }
}

@media screen and (min-width: 1360px) {
  .container {
    max-width: 1280px;
  }
  body {
    font-size: 15px;
  }

  #home .jumbotron {
    padding: 8rem 2rem;
  }
}
/* end media (min-width: 1360px)*/
@media screen and (min-width: 1440px) {
  .filter-view ul {
    margin-bottom: 12px;
    margin-top: 5px;
  }
  .container {
    max-width: 1410px;
  }
  body {
    font-size: 18px;
  }
  .jumbotron {
    padding: 9.5rem 2rem;
  }
  .jumbotron h2 {
    font-size: 2.1em;
  }
  .buy_sell_section .buyleft-section ul {
    margin-right: 2em;
  }

  .buy_sell_section .sellright-section ul {
    margin-left: 2em;
  }
  .heading-style1 h2 {
    font-size: 1.4em;
  }
  .buy_sell_section ul li {
    margin-right: 2.5em;
  }

  #footer-last-col ul li img {
    width: 1.4em;
  }
}
/* end media (min-width: 1440px)*/

@media screen and (min-width: 1680px) {
  .filter-view ul {
    margin-bottom: 7px;
  }
  .container {
    max-width: 1600px;
  }
  body {
    font-size: 20px;
  }
  .buy_sell_section h3 {
    margin-top: 1.2em;
  }
  .video-wrapper iframe {
    height: 450px !important;
  }
}
/* end media (min-width: 1680px)*/
@media screen and (min-width: 1920px) {
  .filter-view ul {
    margin-bottom: 8px;
  }
  .container {
    max-width: 1800px;
  }

  .pro-block .pro-block-img {
    height: 12em;
  }

  body {
    font-size: 22px;
  }

  .shop-content {
    font-size: 20px;
  }
  .btn-sm.btn-style1 {
    font-size: 0.7em;
  }
  .footer,
  .recent_reviews_section {
    font-size: 19px;
  }
  .buy_sell_section h3 {
    margin-top: 0.5em;
  }
  .video-wrapper iframe {
    height: 410px !important;
  }

  .sb-btn-group {
    width: 100%;
    overflow: hidden;
  }
  .buy_sell_section .buyleft-section ul {
    margin-right: 5em;
  }
  .buy_sell_section .sellright-section ul {
    margin-left: 5em;
  }

  .sb-btn-group {
    margin-bottom: 3em;
  }

  .navbar-brand img {
    height: 4.4em;
  }
}
/* end media (min-width: 1920px)*/

@media screen and (min-width: 2500px) {
  .container {
    max-width: 2390px;
  }
  body {
    font-size: 21px;
  }

  .buy_sell_section .img-block img {
    width: 17.5em;
  }
  .buy_sell_section h3 {
    margin-top: 0.5em;
  }
  .video-wrapper iframe {
    height: 500px !important;
  }
  .buy_sell_section .buyleft-section ul {
    margin-right: 10em;
  }
  .buy_sell_section .sellright-section ul {
    margin-left: 10em;
  }
}
/* end media (min-width: 2500px)*/

@media screen and (min-width: 2730px) {
  .buy_sell_section .img-block img {
    width: 19.5em;
  }
}
/* end media (min-width: 2730px)*/

@media screen and (min-width: 3000px) {
  .container {
    max-width: 2900px;
  }
  body {
    font-size: 27px;
  }
  .buy_sell_section .img-block img {
    width: 15em;
  }
  .buy_sell_section h3 {
    margin-top: 0.5em;
  }

  .video-wrapper iframe {
    height: 750px !important;
  }
}
/* end media (min-width: 1920px)*/

#dashboard-tabs .nav-tabs {
  border-bottom: solid 1px #000000;
  margin-bottom: 1.4em;
  position: relative;
}

#dashboard-tabs a.nav-item {
  text-transform: uppercase;
  font-size: 1.1em;
  color: #000;
  padding-left: 1.2em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-decoration: none;
  padding: 5px 15px;
  position: relative;
  margin-right: 15px;
}

#dashboard-tabs a.nav-item.active:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  right: 0;
  height: 3px;
  background: #48a068;
  border-radius: 2px;
}

.order-details p {
  font-size: 0.8em;
  margin-bottom: 0;
  padding-left: 15px;
}

.order-details {
  border-bottom: solid 1px #ccc;
  padding-bottom: 15px;
  margin-bottom: 22px;
}
.order-details h3 {
  margin-bottom: 0;
}
.order-details .order-number strong {
  color: #48a068;
}
.order-details button.view-more {
  border: 0;
  background: transparent;
  text-decoration: underline;
  font-size: 0.8em;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  outline: none;
}
.order-details button.view-more:hover {
  border: none;
  cursor: pointer;
  color: #48a068;
}

#order-details-sec {
  height: 590px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.order-details h2 {
  color: #48a068;
  font-size: 0.8em;
  font-family: "Montserrat", sans-serif;
}
.od-list {
  font-size: 0.8em;
  padding: 0;
}
.od-list label {
  margin-bottom: 0;
  width: 120px;
}
.brdr-none {
  border: none !important;
  padding-bottom: 0;
}
#messages-sec {
  width: 33%;
  float: left;
  height: 590px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.messages-sec-right {
  width: 64%;
  float: right;
}
#messages-sec .ms-date {
  position: absolute;
  top: 15px;
  right: 10px;
}
#messages-sec .ms-delete {
  position: absolute;
  top: 50px;
  right: 10px;
}

.messages-sec-right {
  position: relative;
  /* min-height: 565px; */
  padding-bottom: 100px;
}
.msgWraper {
  height: 588px;
  overflow-y: scroll;
}
.btn-reply {
  position: absolute;
  left: 0;
  bottom: 0;
}
.messages-sec-right p {
  font-size: 0.85em;
  padding: 0;
}
.order-right-filter {
  float: right;
  position: absolute;
  right: 0;
  top: -12px;
  background: #f6f6f6;
  border: 0;
  border-radius: 4px !important;
}
.search-blk {
  width: 250px;
  float: left;
  position: relative;
}
.filter-order {
  width: auto;
  float: right;
  margin-top: 8px;
  text-align: right;
  margin-right: 15px;
}
.search-blk .form-control {
  background: #f6f6f6;
  border: 0;
  border-radius: 4px !important;
  padding-right: 50px !important;
}
.search-blk .btn-search {
  position: absolute;
  right: 5px;
  top: 8px;
  font-size: 0;
  background: url(../images/btn-search.png) no-repeat 0 5px;
  background-size: 20px auto;
  width: 36px;
  height: 36px;
  border: none;
}

.payments-content .th-col,
.payments-content .td-col {
  font-size: 0.8em;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 500;
}
.payments-content .th-col {
  color: #48a068;
  font-size: 0.9em;
  font-weight: 600;
  line-height: 35px;
}
.payments-content .td-col {
  height: 100%;
  vertical-align: middle;
}

.paymentsCon-thead {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.paymentsCon-item-row {
  width: 100%;
  float: left;
  box-shadow: 0 0 3px #ccc;
  margin-bottom: 15px;
  padding: 12px 0;
  border-radius: 8px;
}

.payments-content .tg-pro {
  width: 40%;
  float: left;
  padding-right: 20px;
}
#recent-Products .recent-product-content .tg-product .tg-pro-img,
.tg-pro .tg-pro-img {
  float: left;
  width: 60px;
  margin-right: 15px;
  margin-left: 15px;
  box-shadow: 0 0 3px #ccc;
  padding: 4px;
  border-radius: 6px;
}
#recent-Products
  .recent-product-content
  .tg-product
  .tg-pro-img
  .img-responsive,
.tg-pro .tg-pro-img .img-responsive {
  max-width: 100%;
}

.payments-content .tg-orderNo {
  width: 12%;
  float: left;
}

.payments-content .tg-date {
  width: 11%;
  float: left;
}

.payments-content .tg-amount {
  width: 12%;
  float: left;
}

.payments-content .tg-pay-status {
  width: 10%;
  float: left;
}

.tg-pay-status select {
  background: #fff;
  color: #000;
  padding: 4px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.tg-pay-status select option[value="unpaid"] {
  background: rgba(100, 100, 100, 0.3);
}

.tg-pay-status select option[value="paid"] {
  background: rgba(150, 150, 150, 0.3);
}
.payments-content .tg-pay-methos {
  width: 15%;
  float: left;
}

.tg-pay-status .tdpaid {
  background: green;
}

.tg-pay-status .tdunpaid {
  background: #ff0000;
}

#dashboard .form-control.style2 {
  border-radius: 7px;
  background: #f6f6f6;
  border: solid 1px #f2f2f2;
}

#dashboard .order-status select.form-control {
  padding: 0.4rem 0.85rem;
}
#dashboard .order-status .statusShipped.form-control,
#dashboard .order-status .statusinprogress.form-control {
  color: #2d7e4b;
}
#dashboard .order-status .statusCanceled.form-control {
  color: #ff0000;
}

#dashboard button.btn-save {
  padding: 5px 20px;
  font-size: 0.75em;
  margin-right: 15px;
  margin-top: 4rem !important;
  border-radius: 5px;
}

.order-status label {
  font-size: 0.85em;
}

#statusShipped .order-status label {
  margin-bottom: 0;
}

#statusShipped .order-status input.form-control {
  padding: 0.2rem 0.85rem;
}
@media (min-width: 992px) {
  .modal-order-details {
    max-width: 600px;
  }
}

#recent-Products .th-col,
#recent-Products .td-col {
  font-size: 0.8em;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 500;
}
#recent-Products .th-col {
  color: #48a068;
  font-size: 0.9em;
  font-weight: 600;
  line-height: 35px;
}
#recent-Products .td-col {
  height: 100%;
  vertical-align: middle;
}

.recentCon-thead {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.recentCon-item-row {
  width: 100%;
  float: left;
  box-shadow: 0 0 3px #ccc;
  margin-bottom: 15px;
  padding: 12px 0;
  border-radius: 8px;
}

#recent-Products .recent-product-content .tg-product {
  width: 70%;
  float: left;
  padding-right: 20px;
}

#recent-Products .recent-product-content .tg-price {
  width: 15%;
  float: left;
  font-size: 0.9em;
}

#recent-Products .recent-product-content .tg-action {
  width: 15%;
  float: left;
}

.header-cart {
  margin-left: 15px;
}
.btn-orange {
  background: #fb9d1e;
  border: 0;
  font-size: 22px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

/*  Login page CSS */
#login_page {
  background: #fefdf3;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.login-page .footer {
  margin-top: 0;
}

@media (min-width: 993px) {
  #login_page:before {
    background: #f3fcfc url(../images/login-widget-bg.png) no-repeat 0 100%;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
  }

  #login_page:after {
    background: url(../images/signup-widget-bg.png) no-repeat 100% 20%;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    z-index: 0;
  }

  #login_page .container {
    position: relative;
    z-index: 9;
  }

  .loginBgCenter {
    position: absolute;
    left: 50%;
    bottom: 0;
    background: url(../images/login-bg-center.png) no-repeat 50% 100%;
    width: 415px;
    height: 800px;
    margin-left: -207px;
  }
} /* end media */

@media (min-width: 992px) and (max-width: 1600px) {
  .loginBgCenter,
  #login_page:after,
  #login_page:before {
    background-size: 50% auto;
  }
} /* end media */

@media (max-width: 992px) {
  #login_page {
    padding-top: 0;
  }
  .header-cart {
    display: none;
  }
  #login_page .container .login-left {
    background: #f3fcfc;
    padding-top: 70px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
} /* end media */

#login_page .form-check input[type="checkbox"] {
  margin-top: 8px !important;
}

#login_page .iAgree,
#login_page .forgotPassword {
  font-size: 0.8em;
}

#login_page .forgotPassword a {
  font-size: 18px;
}

#login_page .signUp-widget,
#login_page .login-widget {
  max-width: 430px;
  margin: 0 auto;
}

#login_page p {
  font-size: 0.8em;
  line-height: normal;
  font-weight: 500;
}

#login_page p a {
  color: #48a068;
}

#login_page .rememberMe label,
#login_page .iAgree .form-check-label,
#login_page .donthaveaccount,
#login_page a {
  line-height: normal;
  font-weight: 500;
  color: #595959;
}
#login_page .iAgree label,
#login_page .rememberMe label {
  font-size: 18px;
  line-height: 25px;
}

#login_page .iAgree .form-check-label a {
  color: #1084d6;
  text-decoration: underline;
}

#login_page a:hover {
  color: #000;
}

#login_page h2 {
  color: #48a068;
  font-weight: 600;
  text-transform: uppercase;
}

#login_page h4 {
  color: #fb9d1e;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 0.85em;
}

#login_page h5 {
  color: #595959;
  font-weight: 500;
  font-size: 0.8em;
}

#login_page .form-label {
  color: #595959;
  font-weight: 500;
  font-size: 0.8em;
}

#login_page .form-control {
  border: solid 1px #848787;
  background: #fff;
}

.cart-total * {
  font-size: 0.8em;
}

.cart-total label {
  font-size: 0.85em;
  font-weight: 500;
  color: #558c74;
}

.cart-right .form-label {
  font-size: 0.8em;
}

.cart-items h2 {
  font-size: 1.1em;
  font-weight: 500;
}

.cart-items p {
  font-size: 0.7em;
}

#cart_page .pro-img {
  border: solid 1px #ccc;
  display: block;
  padding: 15px;
  text-align: center;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
}

.otp-container {
  display: flex;
  justify-content: space-between;
}
