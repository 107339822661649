.text-field {
	// margin-bottom: 12px;
	input {
		height: 36px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9fabc6;
		border-radius: 6px;
		width: calc(100%);
		margin-top: 4px;
		padding: 8px 13px;
		outline: none;
		font-family: Montserrat, sans-serif;
		color: #898f8f;
		line-height: 1.43;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
	}
	// input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	//     color: red;
	//     opacity: 1; /* Firefox */
	//   }
	::-webkit-input-placeholder {
		/* Chrome */
		color: #898f8f;
		font-size: 14px;
		font-family: Montserrat, sans-serif;
	}
	textarea {
		// height: 46px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9fabc6;
		border-radius: 6px;
		width: calc(100%);
		margin-top: 4px;
		padding: 8px 13px;
		outline: none;
		font-size: 14px;
		color: #898f8f;
		font-family: Montserrat, sans-serif;
	}
	label {
		font-family: Montserrat, sans-serif;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #898f8f;
	}
	.start-adornment {
		width: 3em;
		height: 26px;
		border-radius: 23px 0 0 23px;
		border: 1px solid rgb(183, 183, 183);
		border-right: none;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 50px;
	}
	.end-adornment {
		width: 3em;
		height: 26px;
		border-radius: 0 23px 23px 0;
		border: 1px solid rgb(183, 183, 183);
		border-left: none;
		display: flex;
		padding: 0 1em;
		justify-content: center;
		align-items: center;
		min-width: 50px;
	}
}

.table-text-field {
	width: 100%;
	input {
		height: 26px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #b7b7b7;
		border-radius: 23px;
		width: calc(100% - 2em);
		padding: 0 1em;
		outline: none;
	}
	textarea {
		// height: 46px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #b7b7b7;
		border-radius: 6px;
		width: calc(100% - 2em);
		padding: 1em 1em;
		outline: none;
	}
	.start-adornment {
		width: 3em;
		height: 26px;
		border-radius: 23px 0 0 23px;
		border: 1px solid rgb(183, 183, 183);
		border-right: none;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 50px;
	}
	.end-adornment {
		width: 3em;
		height: 26px;
		border-radius: 0 23px 23px 0;
		border: 1px solid rgb(183, 183, 183);
		border-left: none;
		display: flex;
		padding: 0 1em;
		justify-content: center;
		align-items: center;
		min-width: 50px;
	}
}

.errorText {
	padding-left: 0px;
	color: #fb4868;
	line-height: 2em;
	font-size: 14px;
	font-family: Montserrat, sans-serif;
	font-weight: 100;
}

.checkbox {
	// margin-bottom: 20px;
	.container {
		display: inline;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		background-color: #eb0a60;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		// height: 25px;
	}

	// Hide the browser's default checkbox /
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	// Create a custom checkbox /
	.checkmark {
		border-radius: 5px;
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
	}

	// On mouse-over, add a grey background color /
	.container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	// When the checkbox is checked, add a blue background /
	.container input:checked ~ .checkmark {
		background-color: #104594;
	}

	// Create the checkmark/indicator (hidden when not checked) /
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	// Show the checkmark when checked /
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	// Style the checkmark/indicator /
	.container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	// label {
	//     margin-left: 25px;
	//     font-size: 24px;
	//     color: #104594;
	//     font-weight: 800;
	//     font-family: 'Montserrat, sans-serif', sans-serif;
	// }
}
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.checkbox-label {
	font-weight: 600;
	color: #878787;
	display: flex;
}
